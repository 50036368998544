<template>
  <section class="bgp-config"
    data-name="sdf-config">
    <h4 class="text-align-center mt-1 mb-2">
      {{ $t(isSfdcVxc ? 'salesforce.configuration' : 'connections.transit-configuration') }}
    </h4>

    <!-- Salesforce approval note -->
    <div v-if="isSfdcVxc"
      class="info-bar">
      <em>{{ $t('general.note') }}:</em> {{ $t('salesforce.approvals') }}
    </div>

    <!-- Enable Megaport Internet (Transit) BGP Config -->
    <el-form-item v-if="!isSfdcVxc && !isExistingConnection"
      prop="serviceObj.bEnd.partnerConfig._enableBgp"
      :label-width="labelWidth">
      <template #label>
        <div class="d-flex flex-align-center flex-justify-end">
          {{ $t('connections.enable-bgp') }}
          <el-tooltip placement="top"
            :content="$t('tooltips.transit-bgp-config')"
            :open-delay="500">
            <i class="fas fa-question-circle color-info popover-info-icon"
              aria-hidden="true" />
          </el-tooltip>
        </div>
      </template>

      <el-checkbox id="enable-bgp"
        v-model="config._enableBgp"
        name="enable-bgp"
        data-testid="enable-bgp" />
    </el-form-item>

    <!-- ASN -->
    <el-form-item :prop="`${bEndPath}.partnerConfig.${asnFieldKey}`"
      :label-width="labelWidth">
      <template #label>
        {{ $t(`connections.${isSfdcVxc ? 'asn' : 'customer-asn'}`) }}
        <el-tooltip placement="top"
          :content="isMcr ? $t('connections.asn-from-mcr') : $t('aws.bgp-asn')"
          :open-delay="500">
          <i class="fas fa-question-circle color-info popover-info-icon"
            aria-hidden="true" />
        </el-tooltip>
      </template>
      <el-input v-model="config[asnFieldKey]"
        type="text"
        data-demo="65333"
        name="asn"
        :disabled="isMcr || disableFields"
        :data-testid="asnFieldKey" />
    </el-form-item>

    <!-- BGP Password -->
    <el-form-item :label-width="labelWidth">
      <template #label>
        {{ $t('connections.bgp-password') }}
        <el-tooltip v-if="isSfdcVxc"
          placement="top"
          :content="$t('salesforce.provide-password')"
          :open-delay="500">
          <i class="fas fa-question-circle color-info popover-info-icon"
            aria-hidden="true" />
        </el-tooltip>
      </template>
      <el-input v-model="config.password"
        :type="showPassword ? 'text' : 'password'"
        name="password"
        data-demo="new-password"
        :disabled="disableFields"
        data-testid="bgp-password">
        <span slot="suffix"
          class="cursor-pointer">
          <i :class="showPassword ? 'fa-eye' : 'fa-eye-slash'"
            class="fa mr-1"
            @click="showPassword = !showPassword" />
        </span>
      </el-input>
    </el-form-item>

    <!-- Prefixes -->
    <el-form-item :prop="`${bEndPath}.partnerConfig.prefixes`"
      :label-width="labelWidth">
      <template #label>
        {{ $t('salesforce.prefixes') }}
        <el-tooltip placement="top"
          :content="isSfdcVxc ? $t('salesforce.prefixes-tooltip') : $t('tooltips.transit-prefixes')"
          :open-delay="500">
          <i class="fas fa-question-circle color-info popover-info-icon"
            aria-hidden="true" />
        </el-tooltip>
      </template>
      <el-input v-model="config.prefixes"
        type="text"
        data-demo="191.0.2.0/24,191.0.2.0/16"
        placeholder="e.g. 192.0.2.0/24,192.0.2.0/16"
        name="prefixes"
        data-testid="prefixes"
        :disabled="disableFields" />
    </el-form-item>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { MEGAPORT_ASN } from '@/Globals.js'

export default {
  name: 'SfdcConfig',

  props: {
    value: {
      type: Object,
      required: true,
    },
    aEndConnection: {
      type: Object,
      default: null,
    },
    isMcr: {
      type: Boolean,
      required: true,
    },
    isExistingConnection: {
      type: Boolean,
      default: false,
    },
    // This component can be used either for Salesforce (SFDC) or Megaport Internet (TRANSIT) connections
    connectType: {
      type: String,
      default: 'SFDC',
      validator: value => ['SFDC', 'TRANSIT'].includes(value),
    },
  },

  emits: ['input'],

  data() {
    return {
      labelWidth: '200px',
      vlan: 0,
      showPassword: false,
      config: {
        asn: null, // SFDC
        customer_asn: null, // TRANSIT
        password: null,
        prefixes: null,
        _enableBgp: false,
      },
    }
  },

  computed: {
    ...mapGetters('Auth', ['hasFeatureFlag']),

    /**
     * Determine if form is complete
     */
    isComplete() {
      return this.isSfdcVxc ? !!this.config.asn : !this.config._enableBgp || !!this.config.customer_asn
    },
    isSfdcVxc() {
      return this.connectType === 'SFDC'
    },
    /**
     * Disable fields for transit connections based on the enable BGP checkbox
     */
    disableFields() {
      // Fields are always enabled for Salesforce and for existing internet connections when the FF is on
      if (this.isSfdcVxc || (this.isExistingConnection && this.hasFeatureFlag('internet_bgp_configuration'))) return false

      return !this.config._enableBgp
    },
    asnFieldKey() {
      return this.isSfdcVxc ? 'asn' : 'customer_asn'
    },
    bEndPath() {
      return this.isExistingConnection ? 'bEndConfig' : 'serviceObj.bEnd'
    },
  },

  watch: {
    config: {
      handler() {
        // Emit every time config object has changed
        this.emitUpdatedPartnerConfig()
      },
      deep: true,
    },
  },

  created() {
    if (this.value.partnerConfig) {
      // This strips out all the keys we are not interested in here and only picks the ones we are interested in
      // for this editing screen. Since this is emitted on the v-model, it automatically strips out the "isLive"
      // key, which means that we can pick that up before submitting.
      Object.keys(this.value.partnerConfig).forEach(key => {
        if (typeof this.config[key] !== 'undefined' && key !== 'complete') {
          this.config[key] = this.value.partnerConfig[key]
        }
      })
      this.emitUpdatedPartnerConfig()
      this.autoFillFields()
    }
  },

  methods: {
    /**
     * Emit partnerConfig details back to CreateConnection
     */
    emitUpdatedPartnerConfig() {
      // Remove the ASN field we don't want depending on the connection type
      this.isSfdcVxc ? delete this.config.customer_asn : delete this.config.asn

      this.$emit('input', {
        vlan: null,
        productUid: this.value.productUid,
        partnerConfig: {
          ...this.config,
          connectType: this.connectType,
          complete: this.isComplete,
        },
      })
    },
    /**
     * Autofill fields
     */
    autoFillFields() {
      if (this.isMcr && Object.hasOwn(this.aEndConnection, 'resources')) {
        this.config[this.asnFieldKey] = this.aEndConnection.resources.virtual_router.mcrAsn.toString()
      } else if (this.isMcr && Object.hasOwn(this.aEndConnection, 'config')) {
        this.config[this.asnFieldKey] = this.aEndConnection.config.mcrAsn?.toString() || MEGAPORT_ASN.toString()
      }
      if (Array.isArray(this.value.partnerConfig.prefixes)) {
        this.config.prefixes = this.value.partnerConfig.prefixes.toString()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.bgp-config {
  min-width: 500px;
}

.info-bar {
  background-color: var(--color-white);
  border: 1px solid var(--color-warning);
  border-radius: var(--border-radius-base);
  padding: 2rem;
  text-align: center;
  width: fit-content;
  margin: auto;
  margin-bottom: 2rem;
  line-height: normal;
}
</style>
